<template>
  <mal-function eventtype="4"></mal-function>
</template>
<script>
import MalFunction from '../malfunction/Index'
export default {
  components: {
    MalFunction
  }
}
</script>